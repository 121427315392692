import sign from "jwt-encode";
import Config from "../app/config";
import moment from "moment";
import cookieUtils from "./cookies";
import { toast } from "react-toastify";

export const logoutUser = async () => {
  toast.error("Your authentication has expired");
  setTimeout(() => {
    localStorage.clear();
    cookieUtils.removeCookie("token");
    window.location.href = "/";
  }, 2000);
};

/**
 *
 * @param array
 * @param key
 * @param value
 * @returns
 */
export const findIndexByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] == value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {*} timestamp
 * @returns
 */
export const formatTimestamp = (timestamp) => {
  const date = moment(timestamp);
  const formattedDate = date.format("dddd DD MMMM, h:mm A");
  return formattedDate;
};

/**
 * Method to convert number into Indian Rupees.
 * @param {*} number
 */
export const convertIntoINR = (number) => {
  if (!number) {
    return number ? number.toString() : ""; // Assuming the input number is stringified if falsy
  }

  number = parseFloat(number.toString());

  const ans = number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return ans.substring(1);
};

export const convertTimestamp = (
  timestamp,
  isDotRemove = false,
  shouldIncludeTime = true
) => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", { month: "short" });
  const year = dateObj.getFullYear().toString().slice(-2);
  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year}${
    shouldIncludeTime
      ? ` ${isDotRemove ? " " : "•"} ${hours}:${minutes} ${ampm}`
      : ""
  }`;
};

export const convertTimestampIntoTime = (timestamp) => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes} ${ampm}`;
};

export const getAddressStringFromObject = (address) => {
  return `${address?.address_details_line1}, ${address?.address_details_line2}, ${address?.city}, ${address?.pincode}, ${address?.state}`;
};

export const findByKey = (array, key, value) => {
  for (const element of array) {
    if (element[key] == value) {
      return element;
    }
  }
  return null;
};

export const generatePublicToken = () => {
  const jwtSignedToken = sign(
    {
      timestamp: Date.now(),
      type: "public",
      role: "buyer",
      scope: "read",
      origin: "website",
    },
    Config.JWT_PUBLIC_KEY,
    {
      alg: "HS256",
    }
  );

  return jwtSignedToken;
};

export const getSecondsPassed = (createdAt) => {
  if (!createdAt) {
    return 100;
  }

  const createdAtTime = new Date(createdAt);
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentTime - createdAtTime;

  // Convert the difference to seconds
  return Math.floor(timeDifference / 1000);
};
