import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLiveStreams } from "./LiveStreamApi";

const initialState = {
  isSignedIn: false,
  authType: null,
  loading: false,
  error: null,
  streams: [],
};

/* eslint no-empty: "error" */
export const getLiveStreamsThunk = createAsyncThunk(
  "liveStream/getLiveStreams",
  async (payload, { rejectWithValue }) => {
    try {
      return await getLiveStreams(payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLiveStreamsThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLiveStreamsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        if (action?.payload?.data) {
          state.streams = action.payload.data;
        }
      })
      .addCase(getLiveStreamsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default authenticationSlice.reducer;
export const { reset, setAuthentication } = authenticationSlice.actions;
