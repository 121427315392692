import React, {lazy, Suspense, useEffect, useState} from "react";
import {Routes, Route, useNavigate, useLocation} from "react-router-dom";
import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "promise-polyfill/src/polyfill";
import {getUserThunk} from "./features/User/UserSlice";
import {setAuthentication} from "./features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {getLiveStreamsThunk} from "./features/LiveStream/LiveStreamSlice";
// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

// Use React.lazy to import components
const MobileHome = lazy(() => import("./pages/mobile/Home/MobileHome"));
const Stream = lazy(() => import("./pages/mobile/Stream/Stream"));
const Login = lazy(() => import("./pages/mobile/Login"));
const StreamVideo = lazy(
  () => import("./pages/mobile/StreamVideo/StreamVideo"),
);
const PaymentConfirmation = lazy(
  () => import("./pages/mobile/PaymentConfirmation/PaymentConfirmation"),
);
const ProductDetail = lazy(
  () => import("./pages/mobile/ProductDetail/ProductDetail"),
);

const App = () => {
  const [loading, setLoading] = useState(false);
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch();
  const {isSignedIn} = useSelector(state => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    onPageLoad();
  }, []);

  const onPageLoad = () => {
    fetchLiveStreams();
  };

  const fetchLiveStreams = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getLiveStreamsThunk());

      if (getLiveStreamsThunk.fulfilled.match(action)) {
        //success
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", true);
          localStorage.setItem("switchMobileView", true);
          if (location.pathname === "/") {
            navigate("/stream"); // Navigation now occurs within the Router context
          }
        }
        setLoading(false);
      } else {
        toast.error("Error fetching user");
        setLoading(false);
        dispatch(setAuthentication(false));
      }
    } catch {
      setLoading(false);
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  return (
    <Suspense
      fallback={
        <div style={styles.container}>
          <h2>Loading...</h2>
        </div>
      }>
      <Routes>
        {!isMobile ? (
          <Route
            path="*"
            element={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15%",
                }}>
                <div style={styles.messageBox}>
                  <h1 style={styles.heading}>
                    Please open this page on a mobile or tablet device
                  </h1>
                  <p style={styles.subText}>
                    This content is optimized for smaller screens. Try resizing
                    or switching to a mobile device.
                  </p>
                </div>
              </div>
            }
          />
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="/stream" element={<MobileHome />} />
            <Route path="/stream/:id" element={<Stream />} />
            <Route path="/stream/:id/*" element={<StreamVideo />} />
            <Route path="/payment" element={<PaymentConfirmation />} />
            <Route path="/product/:id" element={<ProductDetail />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default App;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "linear-gradient(135deg, #f8f9fa, #e9ecef)", // Gradient background
  },
  messageBox: {
    textAlign: "center",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
    maxWidth: "500px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#343a40",
    marginBottom: "10px",
  },
  subText: {
    fontSize: "16px",
    color: "#6c757d",
  },
};
