import axios from "axios";
import Config from "../app/config";
import { generatePublicToken, logoutUser } from "../utils/helpers";
import cookieUtils from "../utils/cookies";

// API configuration
const API_CONFIG = {
  url: Config.API_URL,
  timeout: 10000,
};

// Create an Axios instance with a base URL and any other configuration you need
export const api = axios.create({
  baseURL: API_CONFIG.url,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to set Authorization header with the token
api.interceptors.request.use(
  async (config) => {
    // Get the token
    let token = cookieUtils.getCookie("token");
    // Set Authorization header if token is available
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      token = generatePublicToken();
      config.headers["x-api-token"] = token;
    }

    return config;
  },
  (error) => {
    // Handle request error
    console.error("Request Error:", error);
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors, e.g., logout on 401
api.interceptors.response.use(
  (response) => {
    // You can modify the response data here if needed
    return response;
  },
  (error) => {
    // Handle specific error conditions, e.g., logout on 401 or network errors
    if (error?.response?.status === 401) {
      logoutUser();
    }
    return Promise.reject(error);
  }
);

// Additional API configuration
export const apiConfig = {
  url: Config.API_URL,
  authUrl: Config.AUTH_URL,
  userUrl: Config.USER_URL,
  inventoryUrl: Config.INVENTORY_URL,
  liveStreamUrl: Config.LIVE_STREAM_URL,
  notificationUrl: Config.NOTIFICATION_URL,
  storageUrl: Config.STORAGE_URL,
  paymentUrl: Config.PAYMENT_URL,
  cartUrl: Config.CART_URL,
  orderUrl: Config.ORDER_URL,
  timeout: 30000,
};
