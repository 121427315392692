import {configureStore} from "@reduxjs/toolkit";
import AuthenticationSlice from "../features/Authentication/AuthenticationSlice";
import LiveStreamSlice from "../features/LiveStream/LiveStreamSlice";
import UserSlice from "../features/User/UserSlice";

export const store = configureStore({
  reducer: {
    // add all reducers
    authentication: AuthenticationSlice,
    user: UserSlice,
    liveStream: LiveStreamSlice,
  },
});
